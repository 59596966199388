import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><svg viewBox=\"0 0 335 335\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
                    "    <title>Group 3 2</title>\n" +
                    "    <defs>\n" +
                    "        <polygon id=\"path-1\" points=\"0 0 335 0 335 335 0 335\"></polygon>\n" +
                    "    </defs>\n" +
                    "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
                    "        <g id=\"Group-3\" transform=\"translate(167.500000, 167.500000) scale(-1, 1) translate(-167.500000, -167.500000) \">\n" +
                    "            <mask id=\"mask-2\" fill=\"white\">\n" +
                    "                <use xlink:href=\"#path-1\"></use>\n" +
                    "            </mask>\n" +
                    "            <g id=\"Clip-2\"></g>\n" +
                    "            <path d=\"M126.238071,268.577843 L126.238071,66.422157 L250.520271,167.495036 L126.238071,268.577843 Z M167.5,0 C74.9982218,0 0,74.990516 0,167.495036 C0,260.009484 74.9982218,335 167.5,335 C260.011706,335 335,260.009484 335,167.495036 C335,74.990516 260.011706,0 167.5,0 L167.5,0 Z\" id=\"Fill-1\" fill=\"#BCBCBC\"></path>\n" +
                    "        </g>\n" +
                    "    </g>\n" +
                    "</svg></button>",
                nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><svg  viewBox=\"0 0 335 335\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n" +
                    "    <title>Group 3</title>\n" +
                    "    <defs>\n" +
                    "        <polygon id=\"path-1\" points=\"0 0 335 0 335 335 0 335\"></polygon>\n" +
                    "    </defs>\n" +
                    "    <g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
                    "        <g id=\"Group-3\">\n" +
                    "            <mask id=\"mask-2\" fill=\"white\">\n" +
                    "                <use xlink:href=\"#path-1\"></use>\n" +
                    "            </mask>\n" +
                    "            <g id=\"Clip-2\"></g>\n" +
                    "            <path d=\"M126.238071,268.577843 L126.238071,66.422157 L250.520271,167.495036 L126.238071,268.577843 Z M167.5,0 C74.9982218,0 0,74.990516 0,167.495036 C0,260.009484 74.9982218,335 167.5,335 C260.011706,335 335,260.009484 335,167.495036 C335,74.990516 260.011706,0 167.5,0 L167.5,0 Z\" id=\"Fill-1\" fill=\"#BCBCBC\"></path>\n" +
                    "        </g>\n" +
                    "    </g>\n" +
                    "</svg></button>"

                });
        });
    }

}
